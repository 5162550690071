<template>
    <body>
    <div id="wrapper" class="insure-board bg-light ">
        <AgentNav/>

        <nav aria-label="breadcrumb" class=" d-md-block mb-0">
            <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                <li v-if="getUserData().role !== 'ROLE_CONSUMER'" class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a :href="'/policy/' + summary.customerId">Policy: <span v-if="summary.insured">{{ summary.insured.insured }}</span></a></li>
                <li class="breadcrumb-item active" aria-current="Policy">Cancel Policy: <span v-if="policy">{{ policy.policyNumber }}</span></li>
            </ol>
        </nav>

        <main id="main" role="main">
            <div class="row justify-content-center p-2">
                <div class="col-md-8 card p-3 mb-3 shadow card-radius">
                    <h2 class="main-color pl-3 mb-5"><i class="fas fa-ban"></i> Policy Cancellation Form</h2>
                    <div class="card-body">
                        <dl class="row" style="font-size: .95rem ">
                            <dd class="col-5">Policy Number:</dd>
                            <dd class="col-6">
                                <router-link :to="'/quote/' + policy.quoteId">
                                    <span v-if="policy.policyNumber">{{ policy.policyNumber }}</span>
                                    <span class="spinner-border spinner-border-sm ml-5" v-else></span>
                                </router-link>
                            </dd>

                            <dd class="col-5">Effective:</dd>
                            <dd class="col-6">{{ formatDate(policy.effective) }}</dd>

                            <dd class="col-5">Coverage:</dd>
                            <dd class="col-6">Professional Liability</dd>

                            <dd class="col-5">Premium:</dd>
                            <dd class="col-6">{{ formatMoney(policy.premium)  }}</dd>
                        </dl>

                        <dl v-show="alreadyHasErp">
                            <dd class="alert alert-warning border border-warning">
                                <i class="fas fa-exclamation-triangle"></i> Your policy has ERP. Please contact support at support@draftrs.com to help arrange your policy cancellation and ERP
                                effective date.
                            </dd>
                        </dl>

                        <dl class="row px-0 mt-5">
                            <dd class="col-11 p-2">
                                <label><b>What date do you want the policy cancelled?</b></label>
                                <b-form-datepicker
                                        label-no-date-selected="Cancel Date"
                                        v-model="cancelDate"
                                        style="height: 34px; border-color: purple"
                                        locale="en"
                                        value-as-date
                                        :min="minDate"
                                        :max="maxDate"
                                        :disabled="!summary.policies || alreadyHasErp"
                                        size="md"
                                        placeholder="MM/DD/YYYY"
                                        class="col-12"
                                        @input="paymentInit()"
                                >
                                </b-form-datepicker>
                            </dd>
                        </dl>

                        <div class="col-md-8 form-group mb-3 px-1 d-none" v-show="1 == 0 && !alreadyHasErp && cancelDate">
                            <label class="text-left" :class="erp ? '' : 'is-blank'">
                                <b>Would you like to purchase the Extended Reporting Period?</b>
                                <span class="ml-1">
                                        <i
                                                v-b-tooltip.hover
                                                title="Option for the Insured to purchase a one, two or three year extended reporting period to report claims on the policy when a policy is cancelled.
                                            Typically purchased when a firm is acquired and/or is no longer in business."
                                                class="far fa-question-circle main-color">
                                        </i>
                                    </span>
                            </label>
                            <div class="choice-area">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label class="btn btn-secondary"
                                           v-bind:class="erp == 1 ? 'active' : (erp ? '' : 'is-blank')"
                                    >
                                        <input
                                                type="radio"
                                                class="no"
                                                v-model="erp"
                                                name="options"
                                                value="1"
                                                required
                                        >
                                        <i class="fas fa-check-circle main-color mr-1"></i>yes
                                    </label>
                                    <label class="btn btn-secondary"
                                           v-bind:class="erp == 0 ? 'active' : (erp ? '' : 'is-blank')"
                                    >
                                        <input
                                                type="radio"
                                                class=""
                                                v-model="erp"
                                                name="options"
                                                value="0"
                                                required
                                        >
                                        <i class="fas fa-check-circle main-color mr-1"></i>no
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 card shadow card-radius mt-3 mb-3" v-show="cancelDate && (erp == 0 || erpMonths > 0)">
                    <dl class="row p-4">
                        <div v-if="quote.lob && quote.lob == 'mpl'">
                            <dd class="col-12 mt-0 px-2 pt-2 pb-0 text-muted text-90"><b>* Premium will be refunded pro-rata on the cancelled policy period. If policy is cancelled within the first 3
                                months of the policy period, a service charge of $25 will apply.</b></dd>
                        </div>
                        <div v-else>
                            <dd v-if="quote.payMethod == 'agencyBill'">Premium will be refunded directly between Agent via alternate method. Please jot down the cancel date and refund amount.</dd>
                            <dd v-else-if="quote.state == 'MI'" class="col-12 p-2 font-weight-bold">MI premiums will be refunded pro rata on the cancelled policy period subject to a $25.00
                                non-refundable minimum earned premium.
                            </dd>
                            <dd v-else-if="quote.state == 'GA'" class="col-12 p-2 font-weight-bold">GA premiums will be refunded at 90% of the total pro-rata premium on the cancelled policy period.
                            </dd>
                            <dd v-else-if="quote.state == 'ME'" class="col-12 p-2 font-weight-bold">ME premiums will be refunded at 90% of the total pro-rata premium on the cancelled policy period.
                            </dd>
                            <dd v-else-if="quote.state == 'FL'" class="col-12 p-2 font-weight-bold">FL premiums will be refunded at 90% of the total pro-rata premium on the cancelled policy period.
                            </dd>
                            <dd v-else-if="quote.state == 'AK'" class="col-12 p-2 font-weight-bold">AK Premium will be refunded pro-rata for the remaining policy period less a cancellation fee of 7.5%
                                of the returned premium.
                            </dd>
                            <dd v-else class="col-12 p-2 font-weight-bold text-muted text-90">Premium will be refunded pro-rata on the cancelled policy period subject to a 20% non-refundable minimum
                                earned premium.
                            </dd>
                        </div>

                        <dd class="col-6 p-2">Total Policy Premium:</dd>
                        <dd class="col-4 p-2 align-right">{{ formatMoney(policy.premium)  }}</dd>


                        <dd v-if="mplCancel3Months" class="col-6 p-2">Draftrs Service Charge:</dd>
                        <dd v-if="mplCancel3Months" class="col-4 p-2 align-right">$25</dd>

                        <dd class="col-6 mt-0 p-2 " v-if="erpMonths">Policy Refund Premium:</dd>
                        <dd class="col-4 mt-0 p-2 " v-if="erpMonths">
                                <span v-if="prorate">
                                    -{{ formatMoney(Math.abs(prorate - erpPremium))  }}
                                    <i
                                            v-b-tooltip.hover
                                            title="The remaining premium refunded based on the cancellation date"
                                            class="far fa-question-circle main-color">
                                    </i>
                                </span>
                            <span class="spinner-border spinner-border-sm ml-3" v-else></span>
                        </dd>

                        <dd class="col-6 mt-0 p-2 " v-if="erpMonths > 0">Extended Reporting Premium ({{ erpMonths }} months):</dd>
                        <dd class="col-4 mt-0 p-2 " v-if="erpMonths > 0">
                                <span v-if="erpPremium">
                                    {{ formatMoney(erpPremium)  }}
                                    <i
                                            v-b-tooltip.hover
                                            title="Additional cost for adding Extended Reporting"
                                            class="far fa-question-circle main-color">
                                    </i>
                                </span>
                            <span class="spinner-border spinner-border-sm ml-3" v-else></span>
                        </dd>

                        <dt class="col-6 mt-0 p-2 " v-if="prorate !== null">Total Refund:</dt>
                        <dt class="col-6 mt-0 p-2 " v-else><i class="fas fa-spinner fa-pulse"></i></dt>

                        <dt class="col-4 mt-0 p-2 ">
                                <span v-if="prorate !== null">
                                    {{ formatMoney(prorate)  }}
                                    <i v-b-tooltip.hover title="The amount that will be refunded" class="far fa-question-circle main-color"></i>
                                    <!--                                    <i v-else-->
                                    <!--                                        v-b-tooltip.hover -->
                                    <!--                                        title="ERP Premium minus your cancellation refund requires an additional charge" -->
                                    <!--                                        class="far fa-question-circle main-color">-->
                                    <!--                                    </i>-->
                                </span>
                            <span class=" ml-3" v-else><i class="fas fa-spinner fa-pulse"></i></span>
                        </dt>
                        <dd class="col-12 mt-0 px-2 pt-2 pb-0 text-muted text-80" v-if="prorate !== null && quote.payMethod !== 'agencyBill'"><b>*</b> Credit Card processing fees are non-refundable.
                        </dd>
                        <dd class="col-12 mt-0 px-2 pt-2 pb-0 text-muted text-80" v-if="quote.payMethod === 'agencyBill'"><b>* This is an Agency Bill Cancellation. (No actual money refunds are handled
                            here)</b></dd>
                    </dl>
                </div>

                <div class="col-md-8 card text-center shadow card-radius mt-3 mb-3" v-if="(canCancel() && prorate < 0) || (canCancel() && ['ROLE_SUPER'].includes(getUserData().role))">
                    <div class="card-body">
                        <button @click="cancel()" class="btn btn-primary btn-lg mr-3 mt-2">
                            <span v-if="submitCancel" class="spinner-border spinner-border-sm mr-1 " role="status" aria-hidden="true"></span>
                            Submit Cancel
                        </button>
                        <router-link :to="'/policy/'+customerId">
                            <button class="btn btn-outline-dark btn-lg mt-2">Cancel</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </main>
        <b-modal centered
                 ref="cancel-status-modal"
                 ok-only
                 size="xl"
                 hide-header-close
                 @ok="redirectToPolicy"
        >
            <h6 class="alert p-2" :class="cancelled ? 'alert-success' : 'alert-warning'">{{ cancelMsgTitle }} </h6>
            <div class="row">
                <div class="offset-2 col-md-8">
                    <p class="my-4" v-html="cancelMsg"></p>
                </div>
            </div>
        </b-modal>
        <StandardFooter/>
    </div>
    </body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import axios from 'axios';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import {getUserData} from "../Utils/auth.js";

export default {
    name: "Cancel",
    components: {
        AgentNav,
        StandardFooter
    },
    data: function () {
        return {
            customerId: null,
            quoteId: null,
            quote: {},
            summary: {},
            policy: {},
            prorate: null,
            cancelDate: null,
            minDate: null,
            maxDate: null,
            cancelled: false,
            cancelMsgTitle: 'There was an issue cancelling your policy.',
            cancelMsg: 'Please contact your agent or try again later.',
            submitCancel: false,
            erp: 0,
            erpMonths: '',
            erpPremium: null,
            totalRefund: null,
            transacting: false,
            cs: null,
            alreadyHasErp: false,
            currentPolicyIndex: 0,
            mplCancel3Months: false
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        this.customerId = this.$route.params.customerid; // url order: /quoteId/customerId
        this.quoteId = this.$route.params.id;

        this.getCustomerData();
    },
    methods: {
        getActivePolicy() {
            for (let i in this.summary.policies) {
                if (moment.utc().isBetween(moment.utc(this.summary.policies[i].effective), moment.utc(this.summary.policies[i].expires))) {
                    return this.summary.policies[i];
                }
            }
            return this.summary.policies[0];
        },
        getCustomerData: async function () {

            let summary = {};
            await axios.get('/api/policy/' + this.customerId).then((res) => {
                summary = res.data;
                this.summary = summary;
                this.policy = this.getActivePolicy();
            });


            //min and max dates for datepicker
            let now = new Date();
            now.setDate(now.getDate() + 1);

            let effective = new Date(this.policy.effective);
            effective.setDate(effective.getDate() + 1);
            this.minDate = now > effective ? now : effective; // dont let cancel before effective date

            this.maxDate = this.policy.expires;
            if (this.policy.status == 'cancelled')
                this.redirectToPolicy();
            await axios.get('/api/quote/' + this.policy.quoteId).then((res) => {
                this.quote = res.data;

                // if agencyBill allow the minDate to be the effective date (allow agent to negate the entire policy)
                if (this.quote.payMethod == 'agencyBill' && ['ROLE_SUPER', 'ROLE_ADMIN'].includes(getUserData().role))
                    this.minDate = effective;
            });

            // check if already has erp
            if (this.quote.addlExtendedReportingPeriod && this.quote.extendedReportingPeriodMonths) {
                this.alreadyHasErp = true;
                this.erp = 1;
            }
        },
        formatDate: function (dt, fmt) {
            if (!fmt) {
                fmt = 'MM/DD/YYYY';
            }
            return moment.utc(dt).format(fmt);
        },
        getExpDt: function (eff, term) {
            return moment.utc(eff).add(term, 'months').format('MMMM Do YYYY');
        },
        getCancelDt: function () {
            return moment.utc(this.cancelDate).format('MMMM Do YYYY');
        },
        cancel: async function () {
            this.submitCancel = true;
            if (this.cancelDate && this.prorate || ['ROLE_SUPER'].includes(this.getUserData().role)) {
                await this.cancelPolicy();
            }
            this.submitCancel = false;
        },
        cancelPolicy: async function () {
            let params = {quoteId: this.quoteId, action: 'cancel', billing: {amount: parseInt(this.prorate * 100), id: null, client_secret: true, payment_method: null}, effective: this.cancelDate};
            await axios.post('/api/policy', params)
            this.cancelled = true;
            this.cancelMsgTitle = "Your policy " + this.policy.policyNumber + " has been cancelled.";
            this.cancelMsg = "Your policy " + this.policy.policyNumber + " has been cancelled.<br/> The cancel date is " + this.formatDate(this.cancelDate) + ".";
            window.scroll(0, 0);
            this.transacting = false;
            this.$refs['cancel-status-modal'].show();
        },
        paymentInit: async function () {
            if (this.erp == null)
                return false;

            this.erpPremium = this.prorate = this.totalRefund = null;
            this.cancelDate = moment.utc(this.cancelDate).startOf('day').toISOString();
            this.mplCancel3Months = (moment.utc(this.cancelDate).startOf('day').isBetween(moment.utc(this.policy.effective).startOf('day'), moment.utc(this.policy.effective).startOf('day').add('days', 90)));
            let rq = {
                quoteId: this.policy.quoteId,
                premium: 1,
                ratingId: this.policy.ratingId,
                action: 'cancel',
                effective: this.cancelDate,
                getPaymentIntent: true,
                addlData: {erpMonths: this.erpMonths}
            }

            let res = await axios.post('/api/payment', rq)
            if (this.erpMonths !== '') {
                this.cs = res.data.client_secret
                await this.prepStripe();
                this.prorate = res.data.amount / 100;
                this.erpPremium = res.data.erp.premium;
            } else
                this.totalRefund = this.prorate = res.data.amount / 100;
        },
        redirectToPolicy: function () {
            this.$router.push({name: 'policy', params: {customerid: this.customerId}});
        },
        canCancel: function () {

            if (this.cancelDate && this.prorate != null) {
                if (this.erp == 1 && this.erpMonths > 0)
                    return true;
                else if (this.erp == 0)
                    return true;
            }

            return false;
        },
        prepStripe: async function () {
            let stripePubKey = false;
            await axios.get('/api/config').then((res) => {
                stripePubKey = res.data.stripe_pk;
            });
            this.stripe = await loadStripe(stripePubKey);
            var elements = this.stripe.elements();
            var style = {
                base: {
                    color: "#32325d",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "18px",
                    "::placeholder": {
                        color: "#32325d"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
            this.card = elements.create("card", {style: style});
            // Stripe injects an iframe into the DOM
            this.card.mount("#card-element");
            this.card.on("change", function (event) {
                document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
            });
        },
        getUserData: function () {
            return getUserData();
        }
    }
};
</script>

<style lang="scss">
#payment-form {
    border: 1px solid var(--main-color);
    padding: 10px;
}
</style>